import $ from 'jquery';
import 'popper.js';
import 'jquery-ui';
import 'bootstrap';
import 'bootstrap-table/dist/bootstrap-table.min.js';
import 'bootstrap-table/dist/bootstrap-table.min.css';
import 'cropperjs/dist/cropper.min.js';
import 'cropperjs/dist/cropper.min.css';
import 'jquery-cropper/dist/jquery-cropper.min.js';
import JsonViewer from 'json-viewer-js';




FontAwesome.config.autoAddCss = false
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/svg-with-js.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
// Adding JQuery into the window variable for global use
window.$ = $;
window.jQuery = $;
window.JsonViewer = JsonViewer;
